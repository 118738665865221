.dashboard-pages-conf__header-add {
	color: var(--green-dark);
	margin-left: auto;
}

.dashboard-pages-conf-content {
	display: flex;
	flex-direction: column;
	overflow-x: auto;
}

.dashboard-pages-conf-content__pages {
  display: flex;
  flex-direction: column;
}

.dashboard-pages-conf-content__pages__elem {
	display: grid;
	grid-template-columns: 30px 1fr 80px 80px 30px;
	grid-template-areas: "handle title writing background trash";
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #ccc;
	gap: 10px;
}

.dashboard-pages-conf__handle {
  grid-area: handle;
  cursor: move;
}

.dashboard-pages-conf__title {
  grid-area: title;
}

.dashboard-pages-conf__title label {
  display: block;
  margin-bottom: 5px;
}

.dashboard-pages-conf__title input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
}

.dashboard-pages-conf__writing {
  grid-area: writing;
}

.dashboard-pages-conf__writing label {
  display: block;
  margin-bottom: 5px;
}

.dashboard-pages-conf__writing input {
  width: 100%;
}

.dashboard-pages-conf__background {
  grid-area: background;
}

.dashboard-pages-conf__background label {
  display: block;
  margin-bottom: 5px;
}

.dashboard-pages-conf__background input {
  width: 100%;
}

.dashboard-pages-conf__trash {
  grid-area: trash;
}

 .dashboard-pages-conf-actions__save-btn {
 	border-radius : 0.25em;
 	background-color: var(--gd-primary);
 	color: white;
 }

 .dashboard-pages-conf-actions__save-btn:hover {
 	color: white;
 }

 .dashboard-pages-conf-actions__cancel-btn {
 	border-radius : 0.25em;
 	background-color: var(--dc-secondary);
 	color: white;
 }

 .dashboard-pages-conf-actions__cancel-btn:hover {
 	color: white;
 }

#dashboard-pages-form {
	display: contents;
 }
