.dashboard-pages-container {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  overflow-x: auto;
}

.pages-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-dashboard-pages {
	display: flex;
  flex: 1;
  overflow: hidden;
}

.dashboard-page-item {
 	flex: 1;
  overflow: hidden;
  max-width: 100%;
  border-radius: 15px;
  margin-right: 5px;
	border: 1px solid #ccc;
}

.dashboard-page-item:last-child {
  margin-right: 0;
}

.dashboard-page-item.active-page {
  font-weight: bold;
  font-size: 1.2em;
}

.nav-pills .dashboard-page-item a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  max-width: 100%;
	height: 100%;
}

.nav-pills .dashboard-page-item a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dashboard-page-settings-icon {
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 0;
}
