body {
   color: var(--ag-grey1);
}

/* ****** LAYOUT *** */

.datachain {
    font-family: var(--font-family-title);
    font-size: 3rem;
    font-weight: bold;
    margin-left: 10px;
}

.dc-tm {
    position: relative;
    top: -1.5rem;
    font-size: 0.8rem;
}

.kinda-of-blue {
    background: #243d65 !important;
}

.kinda-of-black {
    background: #232323 !important;
}

.gd-accent {
    color: var(--gd-accent) !important;
}

.hd-accent {
    color: var(--hd-accent) !important;
}

.hover-gd-accent:hover {
    color: var(--gd-accent) !important;
}

.hover-hd-accent:hover {
    color: var(--hd-accent) !important;
}

.default-bg {
    background-color: var(--ag-grey1);
}

.menu-bg-gd-accent:hover {
    background-color: var(--gd-accent) !important;
}

.menu-bg-hd-accent:hover {
    background-color: var(--hd-accent) !important;
}

.active-menu {
    color: #1480a1 !important;
}

.active-menu-orange {
    color: #E9850E !important;
}

.menu-item:hover i {
    color: white !important;
}


.right-menu {
    margin-left: 7%;
    margin-top: 25px;
    padding-bottom: 45px;
}

.right-menu-sticky {
    margin-top: 0px !important;
}

.dc-dx-grid {
    height: 750px !important;
}

.left-menu {
    height: 100vh;
    position: fixed;
    z-index: 1003;
    display: inline-block;
    white-space: nowrap;
    width: 3rem;
    transition: width 1s;
    margin: 45px 30px 0 0;
}

.left-menu, .right-menu {
    float: left;
}


.left-menu:hover {
    width: 14.5rem;
}

.menu-item:hover {
    background-color: var(--hd-accent) ;
}

.menu-item-gd:hover {
    background-color: var(--gd-accent) !important;
}

.left-menu .menu-icon {
    margin-left: 5px;
    margin-right: 15px;
    width: 36px;
    color: #fff;
}

.left-menu .font-icon {
    margin-left: 10px;
    margin-right: 20px;
    width: 56px;
    color: #fff;
}

.menu-item {
    height: 2.75rem;
    overflow: hidden;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.menu-item img {
    flex: 0 0 auto;
    object-fit: scale-down;
}

.submenu-item {
    position: relative;
		height: 2.15rem;
    background: var(--ag-grey1);
}

.fixed-item {
    transition: width 1s;
    position: fixed;
    bottom: 0px;
    width: 70px;
}

.fixed-item-hd:hover {
    background-color: var(--hd-accent);
}

.fixed-item-gd:hover {
    background-color: var(--gd-accent);
}

.menu-item-hover-width {
    width: 222px;
}

.height-auto {
    height: auto;
}

.sub-menu-item-name {
    margin-left: 28%;
    font-size: 13px;
}

.submenu-caret {
    margin-top: 6px;
    margin-left: -13px;
    margin-right: 10px !important;
}


.left-menu-widget-directive {
    background: #232323 !important;
    margin: -5px 30px 0px -5px;
    height: 93.5%;
    width: 70px;
}

.menu-item-widget:hover {
    background-color: #ffdd47 !important;
    color: black;
}

.active-menu-item-widget {
    color: #ffdd47 !important;
}

.active-menu-item-widget-icon {
    margin-left: 10px !important;
}

.left-menu-widget-directive:hover {
    width: 240px;
}

.widget-menu-popup-height {
    height: 710px;
}

/***********************/
.dc-tabpanel {
    margin: 5px;
}

.persisted, .exposed-db {
    color: var(--gd-accent) !important;
}

.persisted-details {
    text-align : left;
    width : 300px;
    z-index: 1001;
    overflow-y: auto;
    overflow-x: hidden;
    left: -400px;
    white-space: normal;
}

/* Override bootstrap gutters */
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-padding {
  padding-right: 0;
  padding-left: 0;
}

/* *********************** */

.type-icon-c {
    margin: 2px;
    width: 25px;
    display: inline-block;
}

.icon-del-all {
    color: var(--ag-grey1);
}


.icon-del-all:hover {
    color: var(--hd-accent);
}

/* ****** BUTTONS ******** */
.dc-btn {
    font-weight : bolder;
    border-radius: 4px;
    height : 30px;
    margin: 2px;
}

.dc-btn:disabled{
    cursor: not-allowed;
}

.dc-btn[disabled] {
    opacity: 0.7;
}

.dc-btn-bgr {
    border : none !important;
    color : #ffffff;
}

.dc-btn-bgr:hover {
    opacity: 0.8;
}

.dc-btn-bgr-green {
    background-color: #578445;
}

.dc-btn-bgr-red {
    background-color: #f90841;
}

.dc-btn-bgr-blue {
    background-color: #2c3c90;
}

.dc-btn-bgr-blue-white-text {
    background-color: #2c3c90;
    color: #fff;

}

.dc-btn-bgr-blue-white-text:hover {
    color : #ffffff;
    background-color: #0E4C92;
}

.dc-btn-nobgr {
    background-color: #fff;
}

.dc-btn-nobgr-warn {
    color : var(--warning-color);
    border: 0.5px var(--warning-color) solid;
}

.dc-btn-nobgr-green {
    color : #578445;
    border: 0.5px #578445 solid;
}
.dc-btn-nobgr-green:hover {
     color : #ffffff;
     background-color: #578445
}


.dc-btn-nobgr-primary {
    color : var(--dc-primary);
    border: 0.5px var(--dc-primary) solid;
}
.dc-btn-nobgr-primary:hover {
     color : #ffffff;
     background-color: var(--dc-primary)
}

.dc-btn-nobgr-red {
    color : #f90841;
    border: 0.5px #f90841 solid;
}
.dc-btn-nobgr-red:hover {
     color : #ffffff;
     background-color: #f90841
}

.dc-btn-nobgr-blue {
    color : #2c3c90;
    border: 0.5px #2c3c90 solid;
}
.dc-btn-nobgr-blue:hover {
    color : #ffffff;
    background-color: #2c3c90;
}

.dc-btn-nobgr-grey {
    color : #6c757d;
    border: 0.5px #6c757d solid;
}

.dc-btn-nobgr-black {
    color : #000000;
    border: 0.5px #000000 solid;
}
.dc-btn-nobgr-purple {
    color : #800080;
    border: 0.5px #000000 solid;
}

.dc-btn-nobgr-purple:hover {
    color : #ffffff;
    background-color: #800080;
}

.dc-btn-nobgr-black:hover {
    color : #ffffff;
    background-color: #6c757d;
}

.dc-btn-nobgr-grey:hover {
    color : #ffffff;
    background-color: #6c757d;
}

.dc-dropdown-menu{
    margin-left: -100px;
    z-index: 1001;
    overflow: visible;
}

.mapping-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
    text-decoration: underline;
    cursor: pointer;
}

.dc-panel-tight {
    margin-top: 0px !important;
    height: 1vh !important;
}
/* ***********  TYPES STYLES ************* */

.string {
    font-style: italic;
    color: green;
}
.decimal {
    font-style: italic;
  color: red;
}
.date {
  color: blue;
}
.integer {
  color: red;
}
.big_integer {
  color: orange;
}
.geo {
  color: black;
}

/**** Override Panel definition ****/

.panel {
    margin-bottom: 10px;
    border: 0 !important;
    display: block;
    box-shadow: none;
}

.panel-default {
    border-width: 0px !important;
    border-radius: 0px;
}

.panel-default > .panel-heading {
    padding-left: 0px;
    font-weight: bold;
    font-size: 1.2em;
    background: none;
    border-bottom: solid 2px var(--dc-primary);
    margin-bottom: 5px;
}

.panel-body {
    margin-top: 18px;
    margin-bottom: 15px;
    padding: 10px 0px;
}
/*** GD nav bars ***********/
.gd-nav-tabs {
    border-bottom: 2px solid transparent;
    margin-bottom: 5px;
}

.gd-nav-tabs>li.active>a,
.gd-nav-tabs>li.active>a:focus,
.gd-nav-tabs>li.active>a:hover {
    color: var(--gd-accent) !important;
    border: 0px !important;
    border-bottom: 2px solid var(--gd-accent)! important;
}

.gd-nav-tabs > li > a:focus, .gd-nav-tabs > li > a:hover {
    text-decoration: none;
    color: var(--gd-accent);
}

.gd-nav-tabs > li > a:hover {
    border: 0px;
    border-bottom: 2px solid var(--gd-accent);
}

/******************************/
/* HD nav bars ***/

.hd-nav-tabs {
    border-bottom: 2px solid transparent;
    margin-bottom: 5px;
}

.hd-nav-tabs>li.active>a,
.hd-nav-tabs>li.active>a:focus,
.hd-nav-tabs>li.active>a:hover {
    color: var(--hd-accent) !important;
    border: 0px !important;
    border-bottom: 2px solid var(--hd-accent)! important;
}

.hd-nav-tabs > li > a:focus, .hd-nav-tabs > li > a:hover {
    text-decoration: none;
    color: var(--hd-accent);
}

.hd-nav-tabs > li > a:hover {
    border: 0px;
    border-bottom: 2px solid var(--hd-accent);
}

/*****************************************/

.nav-tabs a {
    color: var(--ag-grey1);
}


.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: var(--ag-grey1);
    cursor: default;
    background-color: #fff;
    border: 0px;
    border-bottom: 2px solid var(--ag-grey1);
}

.dc-nav-font-icon {
    width: 24px;
    color: var(--ag-grey2);
    margin-right: 7px;
}


/********************************************************/


.product {
    font-color: var(--ag-grey1);
    font-weight: bold;
    font-size: 15px;
    margin: 0px 5px;
}

.link-name-list-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid;
    width: 100%;
    padding: 5px;
}

/***************************************************************/

/**** HD Config ******/

.config-group {
    margin: 5px 0px;
    background-color: #cfdee4;
    padding: 10px;
}

/************/

.dc-import-switch-c {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.dc-panel-body-mapping {
    margin-left: 10px;
    margin-right: 10px;
}

.dc-panel-op-height {
    height: 700px;
}

.dc-modal-filter {
    height: 750px;
}

/*** Project HOME ****/

.s-title {
    margin-top: 30px;
}

.section-title {
    font-size: 28px;
    color: var(--ag-grey2);
    font-weight: bold;
}

.pj-stats {
    display: flex;
    flex-wrap: wrap;
    padding:20px;
    margin: 10px;
    justify-content: flex-start;
}

.pj-stat {
    display: flex;
    flex-direction: column;
    flex: 0 1 250px;
    height: 100%;
    justify-content: space-between;
    border: var(--ag-grey3) solid 1px;
    color: var(--ag-grey2);
    line-height: 1;
    overflow: hidden;
    margin: 20px;
    font-family : var(--font-family);
    background-color: var(--ag-grey4);
    padding: 30px;
  }

.pj-stat a {
    text-decoration: none;
    color: var(--ag-grey2);
}

.pj-stat.hd a:hover {
    color: var(--hd-accent) !important;
}

.pj-stat.gd a:hover {
    color: var(--gd-accent);

}

.count_value {
    font-size: 64px;
    font-weight: bold;
    padding-left: 10px;
    margin: 5px;
}

.stat-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}
/********************** CARD STYLE **************************/

.dc-cards-container {
    /*height: 840px;*/
		margin-bottom: 3rem;
    overflow-y: auto;
}

.dc-cards {
    display: flex;
    flex-wrap: wrap;
    padding:5px;
    justify-content: flex-start;
 }

.dc-card {
    display: flex;
    flex-direction: column;
    flex: 0 1 450px;
    justify-content: space-between;
    border: var(--ag-grey3) solid 1px;
    border-radius: 4px;
    color: var(--ag-grey1);
    line-height: 1;
    overflow: hidden;
    margin: 10px;
    font-family : var(--font-family)
  }

.dc-card:hover {
    box-shadow: 3px 3px 3px 1px var(--ag-grey3);
    transform: translate(-3px, -3px);
 }

.card-row {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.card-row-item {
    display: flex;
    flex-direction: row;
    margin: 0px 5px;
    align-items: center;
}

.card-item-title {
    flex-grow: 1;
    font-size: 16px;
    overflow: hidden;
    font-family: var(--font-family-title);
}

/*************************************************************/

/************************* HEADER ************************/


.fixed-bar {
    position: fixed;
    top: 45px;
    padding-top: 15px;
    z-index: 1002;
    height: 55px;
    width: 85%;
    background-color: #ffffff !important;
}

.element-title {
     margin: 8px 0px 0px 0px;
     padding: 0px;
     display: flex;
}

.element-title i {
    margin-right: 10px;
}


.element-bar-42-45 {
     margin: 0px 15px;
 }

 .element-bar-28-30 {
/*     border: solid 1px #ebf0fa;*/
     margin: 0px 15px;
 }

.label-img {
    display: inline-block;
}

.label-img img {
    width: 36px;
    height: 36px;
}

.label-img a {
    color: var(--ag-grey1);
}

.title-container {
    display: flex;
    flex-direction: row;
    width: 85% ! important;
    padding-left: 0px;
    padding-right: 0px;
}

.left-container {
    padding-left: 0px;
    padding-top: 0px;
    display: flex;
    align-items: center;
}

.left-container i {
    margin-right: 10px;
}

.left-container label {
    margin-right: 10px;
    margin-bottom: 0px;
}

.right-container {
    padding-right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.single-button {
    margin-right: 3px;
}

.ellipsis-container {
    align-items: center;
    display: flex
}

.ellipsis-container > a > i  {
    align-items: center;
    display: flex
}


.sub-title-container {
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    width: 100%;
}

.sub-title-container > i {
}

.dc-tool-bar {
    background-color: #f9f9f9 !important;
    min-height : 30px
}

/***********************************************************/

.exp-warning {
    color: var(--warning-color);
}

.exp-alert {
    color: var(--alert-color);
    font-weight: bold;
}

.dc-modal-content {
    padding: 5px;
}

.toggle.btn-sml {
    min-height: 30px;
}

.btn-success{
    background-color : #578445;
    border-color : #578445;
}

.center-div{
   list-style: none; display: flex;
   align-items: center;
   justify-content: center
}

.datablock-total-count{
     color: var(--green-dark);
     width: 380px;
     text-align: center;
     z-index: 10;
     margin-bottom: 10px;
}

.toggle.ios, .toggle-on.ios, .toggle-off.ios {
    border-radius: 10px;
}

.dc-dot {
  border-radius: 50%;
  display: inline-block;
  border: 2px solid;
  border-color: transparent;
}

.dc-dot-15{
  height: 15px;
  width: 15px;
}

.dc-bordered-dotted {
    margin: 3px;
    padding: 3px;
}

.dc-bordered {
    border: 0.5px solid gray;
    border-radius: 5px;
}

.dc-padding-2{
    padding-left : 2px; padding-right : 2px;
}

.dc-new-chart-bordered-div {
    margin: 10px;
    padding: 10px;
    border: 0.5px dotted #000000;
    border-radius: 10px;
    height : 120px;
}

.dc-grid-cell-height {
    height : 40px;
}

.dropdown-menu-fixed-position .dropdown-menu {
    max-height: 200px;
    overflow : auto;
    max-width: 230px;
}

.font-weight-normal {
    font-weight : normal;
}

.dc-icon-c {
    display: flex;
    align-items: center;
    height: 20px;
    margin: 5px 0px;
}

.dc-icon-c i {
    margin: 0px 5px;
}

.dc-icon-sep {
    border-right: 1px solid var(--ag-grey1);
}

.dc-icon {
   font-size: 17px;
   color: var(--ag-grey2);
}

.dc-icon:hover {
   filter: brightness(120%);
}
/**********   TAGS  *******************/

.tags-c {
    max-height: 40px;
    overflow-y: hidden;
    overflow-x: auto;
    border: 0px !important;
    margin: 0px;
    display: flex;
    flex-flow: wrap;
}


.dc-dx-tag-content {
    font-size: 0.9em;
    font-weight: bold;
    display: flex;
    margin: 1px 3px;
    padding: 3px 5px;
    min-width: 0px;
    border-radius: 0.75rem;
    color: var(--ag-grey1);
    max-width: 85px;
    max-height : 18px;
    align-items: center;
}

/* *** */

.db-hd-elements-c {
    display: flex;
    flex-direction: column;
}

.db-hd-element {
    display: flex;
    margin: 5px;
    font-size: 18px;
}

.db-hd-element a {
    color: var(--ag-grey1);
    text-decoration: none;
}

.db-hd-element a:hover{
    color: var(--gd-accent);
    }
/**** SWITCH ******************/

.switch-label {
    display: flex;
    flex-direction: row;
}

.switch-right-align {
    justify-content: flex-end;
}

.switch-label label {
    margin: 0px 10px 0px 0px;;
}

md-switch.tight {
    margin: 0px 5px;
    display: inline-flex;
}

/*md-switch .md-bar  {
    height: 10px !important;
    width: 30px;
}*/

md-switch .md-bar  {
    height: 20px !important;
    width: 38px;
    margin-left: -5px;
    margin-top: -5px;
    /*border: solid 1px var(--ag-grey3);*/
}

md-switch  .md-thumb {
   height: 16px !important;
   width: 16px !important;
   cursor: pointer;
   left: -1px !important;
}

.switch-centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    align-items: center;
}

.switch-centered md-switch {
    margin: 0px;
}

.switch-centered > md-switch > .md-container {
    margin: 0px;
}

.switch-all {
    margin-bottom: 2px !important;
}

.switch-all.md-checked .md-thumb{
    left: 0px;
}

.switch-all .md-thumb {
    width: 15px !important
}

md-switch .md-thumb {
    background-color: var(--ag-grey4);
}

md-switch .md-thumb:before {
    display: flex;
    align-items: center;
    justify-content: center;
    /*content: '\00D7';*/
    color: black;
}

md-switch.md-checked .md-thumb:before {
    display: flex;
    align-items: center;
    justify-content: center;
    content: '\2713';
    color: var(--green-dark);
}

md-switch .md-bar {
    border-radius: 10px;
}

md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
    /*background-color: var(--ag-grey4);*/
    background-color:  var(--green-dark);
}

md-switch.md-default-theme .md-bar, md-switch .md-bar {
    background-color: var(--ag-grey3);
}

md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
   background-color:  white;
}

md-input-container.md-default-theme .md-input, md-input-container .md-input {
    border-color: var(--ag-grey1);
}

md-switch[disabled] .md-bar {
    background-color: rgba(51, 51, 51, .15); /* set disabled bar color */
}
/************************************/

.dc-chips-item {
  text-align : center;
  max-width: 100px;
  border-radius: 15px;
  background-color : var(--dc-secondary);
  height : 28px;
  padding: 3px 8px 3px 8px;
  font-size: 16px;
  margin: 2px;
}

.dc-color-alert {
   color: #FB8500;
}

/************************************/

.modal-ranking {
    min-height: 260px;
    max-height: 700px;
    overflow-y: auto;
}

.semantic-rules {
    padding-left: 10px;
    margin-bottom: 5px;
}

.formula-icons-c {
    align-items: center;
}

.formula-icons-c i {
    margin: 0px 5px;
}

.f-label {
    width: 15%;
}

.f-active {
    width: 5%;
}

.f-actions {
    width: 10%;
}

.f-descr {
    width: 20%;
}

.f-formula {
    width: 50%;
}

.formula-icons-c md-switch {
    margin: 0px 5px;
}

.circle-img-60 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.dc-grey2 {
    color: var(--ag-grey2);
}

.overflow-auto{
    overflow : auto
}

.overflow-hidden{
    overflow : hidden
}

.move-cursor {
	cursor: move;
}

.md-switch-bootstrap {
	margin: 4px !important;
	float: left;
}

.iso-date-tooltip .dx-popup-content {
    background-color: var(--ag-grey2);
    color: var(--light-text-color);
}

.iso-date-tooltip.dx-popover-wrapper .dx-popover-arrow:after {
    background-color: var(--ag-grey2);
}
