.dx-gallery-item {
    max-width: 100%;
}

.hd-img-container-zoom  {
	overflow: auto;
}

.hd-img-container img {
		object-fit: contain;
		max-width: 100%;
		max-height: 100%;
}

.dx-gallery-item .hd-img-container,
.dx-gallery-item .hd-img-container-zoom {
    max-width: 100%;
    max-height: 100%;
}

.dx-gallery-item .item-price {
    font-size: 20px;
    font-weight: bold;
}
