:root {
  --grey-background-color: #EEEEEF;
  --white-background-color: #FFFFFF;
  --grey-btn-background-color: #ABABAC;
  --red-color : #D71414;
  --dc-primary-color: #666666;
  --ag-primary-color: #333333;
  --duplicate-header-template-row:  0.3fr;
  --export-header-template-row:  0.2fr;
  --import-header-template-row:  0.08fr;
  --duplicate-project-template-row:  0.93fr;
  --export-project-template-row:  0.65fr;
  --import-project-template-row:  0.82fr;
  --duplicate-footer-template-row:  0.05fr;
  --export-footer-template-row:  0.1fr;
}

.modal_body_duplicate {
	position: relative;
	height: calc(100vh - 200px);

	overflow-y: auto;
}

.modal_body_import {
	position: relative;
	height: calc(100vh - 250px);
	padding: 15px;
	overflow-y: auto;
}

.modal_body_export {
	position: relative;
	height: calc(100vh - 100px);
	overflow-y: auto;
	padding: 15px;
}

.duplicate_project_container {
	display: grid;
	grid-template-areas: "header header header"
											 "project project project"
											 "footer footer footer";
	grid-template-rows: var(--duplicate-header-template-row) var(--duplicate-project-template-row) var(--duplicate-footer-template-row);
	height: 100vh;
	overflow: hidden;
}

.modal_duplicate_import_export_header {
	padding: 10px;
	border-bottom: 1px solid #e5e5e5;
	max-height: 40px;
	background-color: #f4f6f7;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
}

.modal_duplicate_import_export_footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
	align-items: center;
}

.export_project_container {
    display: grid;
    grid-template-areas: "header header header"
                         "project project project"
                         "footer footer footer";
    grid-template-rows: var(--export-header-template-row) var(--export-project-template-row) var(--export-footer-template-row);
		height: 100vh;
		overflow: hidden;
}

.duplicate_project_header_area {
    grid-area : header;
}

.import_project_header_area {
    grid-area : sourceProject;
}

.duplicate_project_modal_width_height {
	width: calc(100vw - 4.5px);
	height: 100vh;
}

.regex_validation {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 500;
    font-size: 0.75em;
    color: red;
}

.center_loading_progress_bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.duplicate_project_details_stats_exposure_area {
    grid-area : project;
    display: grid;
    grid-template-areas: "projectDetails elementToDuplicate dataBlockExposures";
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 1em;
}

.duplicate_project_details_stats_exposure_area_progress {
    grid-template-columns: 1fr 3fr 1fr;
}

.duplicate_project_projectStats_area {
    grid-area : elementToDuplicate;
    padding-left: 0.25em;
}

.duplicate_project_projectDetails_area {
    grid-area : projectDetails;
    padding-left: 0.25em;
}

.duplicate_project_dataBlockExposures_area {
    grid-area : dataBlockExposures;
}

.duplicate_project_footer_area {
    grid-area : footer;
    display: flex;
    justify-content: flex-end;
}

.duplicate_project_title_container {
    font-weight: 600;
    font-size: 1em;
    font-family: var(--font-family-title);
    color: var(--ag-primary);
    background-color: var(--grey-background-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.938em;
}

.duplicate_project_label_container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0.25em;
    padding: 2em 0em 0.325em 0em;
}

.duplicate_project_label_style {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.313em;
    font-family:  var(--font-family-title);
    font-weight: 300;
    font-size: 1em;
    color: var(--dc-primary-color);
}

.duplicate_to_project_style {
    height: 100%;
    padding: 0.313em;
    display: flex;
    align-items: center;
    font-family:  var(--font-family-title);
    font-weight: 400;
    font-size: 1em;
    color: var(--ag-primary-color);
}

.input_style {
    border: 1px solid #D5D5D6;
    width: 100%;
    border-radius: 0.25em;
}
::placeholder {
    padding: 0.25em;
    font-family:  var(--font-family);
    font-style: italic;
    font-weight: 400;
    font-size: 0.75em;
}

.input_text_style {
   font-size: 0.85em;
   font-weight: 400;
   font-family: var(--font-family);
}

.input_text_style label {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
 }

.exposition_duplicate_datablock {
   font-weight: 400;
   font-family: var(--font-family-title);
   color: var(--ag-primary-color);
   font-size: 1em;
}

.none_exposed_datablock_style {
    font-size: 0.75em;
    font-weight: 500;
    font-family: var(--font-family);
    font-style: italic;
    color: var(--dc-primary-color);
}

.duplicate_project_submit_button {
    display: flex;
    align-items: center;
    border-radius : 0.25em;
    background-color: hsl(180, 100%, 20%);
    color: white;
    margin-left : 1rem;
    padding: 0.188em 0.375em;
    border: 1px solid transparent;
}

.disable_button {
	opacity: 0.5;
}

.duplicate_project_cancel_button {
    display: flex;
    align-items: center;
    border-radius : 0.25em;
    background-color: var(--grey-btn-background-color);
    color: white;
    margin-left : 1rem;
    padding: 0.188em 0.375em;
    border: 1px solid transparent;
}

.padding-bottom-24 {
    padding-bottom: 24px;
}

.padding-bottom-42 {
    padding-bottom: 42px;
}

.padding-8 {
    padding: 8px;
}

.width_45_percent {
   width: 45%;
}


.padding-left-34 {
    padding-left: 34px;
}

.padding-bottom-12 {
    padding-bottom: 12px;
}

.padding-bottom-16 {
    padding-bottom: 16px;
}

.padding-left-4 {
    padding-left: 4px;
}

.padding-left-8 {
    padding-left: 8px;
}

.font-size-10 {
    font-size: 10px;
}

.width_82 {
    width: 82px;
}

.width_125 {
    width: 125px;
}

.exposed_list_header_layout {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-btn-background-color);
}

.new_access_point_container_validation{
		background-color: var(--white-background-color);
		width: 95%;
	  border: 1px solid var(--red-color);
	  display: flex;
	  justify-content: flex-start;
	  position: static;
		left: 0.3125em;
		right: 0.3125em;
		top: 0.3125em;
		bottom: 0.3125em;
}

.duplicate_progression_bar_status {
	font-family: var(--font-family-title);
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	text-align: center;
	color: var(--ag-primary-color);
	padding-bottom: 30px;
}

.project-op-progress-failed {
    background-color: var(--grey-btn-background-color);
    border: 1px solid var(--grey-btn-background-color);
}




