@use '@angular/material' as mat;
@use "@datachain/adb-common/assets/styles/components";
@use "scss/colors" as clr;

@import 'scss/dependencies';
@import 'scss/dc-global';
@import 'scss/typography';
@import 'scss/variables';
@import 'scss/generics-theme';
@import "scss/dxtreme";

@import "scss/app-dialog";
@import "scss/scrollbar";
@import "scss/common";

// styles overrides per component
@import "scss/export-template";
@import 'scss/app-expositions-stepper';
@import 'scss/app-exposition-view';


* + * {
	.app-tooltip__icon {
		align-self: start;
	}

	app-panel {
		--icon-line-height: 1rem !important;
		--search-height: 2rem !important;
		--card-badge-clr: #{mat.get-color-from-palette(clr.$gd-theme-primary, 500)} !important;
	}

	app-popover {
		--popover-badge-clr: #{mat.get-color-from-palette(clr.$gd-theme-primary, 500)} !important;
		--popover-header-icon: #{mat.get-color-from-palette(clr.$gd-theme-primary, 500)} !important;
	}

	app-overlay-badge {
		--icon-clr: #{clr.$grey-dark4} !important;
		--badge-padding: 0 0.125rem !important;
		--icon-txt-spacing: 0.125rem !important;
	}

	app-modal {
		--modal-footer-padding: 1rem 1.5rem !important;
		--modal-header-padding: 0.5rem 0.5rem 0 1rem!important;
	}
}
