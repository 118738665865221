app-export-template {
	app-export-template-details {
		app-nav-doc {
			.mat-icon {
				aspect-ratio: 1;
				width: 21px;
			}
		}
	}

	app-tag-configurator {
		.app-tags {
			.mat-form-field-flex {
				padding-top: 0;
				.mat-form-field-infix {
					padding: 0;
				}
			}
		}
	}

	app-form-card {
		mat-card {
			border: solid 1px rgba(0, 0, 0, 0.30) !important;
		}
	}
}
