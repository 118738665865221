@import 'scss/variables';


app-expositions-list {
	#expositions-list {
		.dx-datagrid .dx-row > td {
			padding: 2px;
		}
		.dx-datagrid-content .dx-datagrid-table .dx-row > td,
		.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
			vertical-align: middle !important;
		}
	}
}

app-exposition-datablock-list {
	#expositions-datablock-list {
		.dx-datagrid-content .dx-datagrid-table .dx-row > td,
		.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
			vertical-align: middle !important;
		}
	}

	.mat-badge-content {
		font-size: 0.7rem !important;
		font-weight: normal !important;
		min-width: 1rem !important;
		width: auto !important;
	}
}

app-exposition-columns-config {
	#exposition-column-config {
		.dx-datagrid-content .dx-datagrid-table .dx-row > td,
		.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
			vertical-align: middle !important;
		}
		.dx-datagrid-rowsview
			.dx-selection.dx-row:not(.dx-row-focused)
			> td.dx-focused {
			background-color: #e6e6e6 !important;
		}
		.dx-state-hover > td.dx-focused {
			background-color: #f5f4f4 !important;
		}
		.dx-datagrid-focus-overlay {
			display: none;
		}

		.dx-invalid-message.dx-overlay {
			position: unset !important;
		}

		.dx-invalid-message-content {
			max-width: fit-content !important;
		}
	}
}

app-exposition-consumer-list {
	#exposition-consumer-list {
		.dx-datagrid-content .dx-datagrid-table .dx-row > td,
		.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
			vertical-align: middle !important;
		}
		.dx-state-hover {
			.delete-template {
				display: block;
			}
		}
	}
}

app-exposition-access-config {
	.mat-expansion-panel-header {
		height: 3rem !important;
	}
	.mat-option-text {
		display: flex !important;
		align-items: center !important;
	}
	.mat-badge-content {
		font-size: 0.7rem !important;
		font-weight: normal !important;
		min-width: 1rem !important;
		width: auto !important;
	}
}

app-expositions-stepper,  app-exposition-migration-stepper{
	.mat-expansion-panel-header {
		height: 3rem !important;
	}
	.mat-step-label-selected {
		font-weight: bold;
	}
	.mat-badge-content {
		font-size: 0.7rem !important;
		font-weight: normal !important;
		min-width: 1rem !important;
		width: auto !important;
	}

	.mat-horizontal-stepper-header-container {
		width: 50% !important;
	}

	@media screen and (max-width: $tablet-breakpoint-width) {
		.mat-horizontal-stepper-header-container {
			width: 70% !important;
		}
	}
}
