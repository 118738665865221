.dashboard-grid-cell-content {
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
}

.dashboard-grid-cell__info {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.35rem;
}

.dc-modal-container.titles-conf-size {
 	width: 80vw !important;
 }

 .dashboard-titles-conf-actions__save-btn {
 	border-radius : 0.25em;
 	background-color: var(--saveUriParam-btn-clr);
 	color: white;
 }

 .dashboard-titles-conf-actions__save-btn:hover {
 	color: white;
 }

 .dashboard-titles-conf-actions {
 	margin-top: auto;
 	margin-left: auto;
 	--saveUriParam-btn-clr: var(--gd-primary);
 }

 .dashboard-titles-conf-content {
 	display: flex;
 	flex-direction: column;
 }

 .dashboard-modals-conf__header-close {
 	margin-left: auto;
 }



